<template>
  <description-wrap :description="party.description" :lines="lines" />
</template>

<script>
import DescriptionWrap from '../global/DescriptionWrap.vue';

export default {
  components: { DescriptionWrap },
  props: {
    party: {
      type: Object,
      required: true,
    },
    lines: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style></style>