<template>
  <div id="ticket-group-selector">
    <div
      class="mb-2 align-center justify-space-between"
      :class="{
        'd-flex': !$vuetify.breakpoint.xsOnly,
      }"
    >
      <h5 class="mb-0">Comprar ingresso{{ maxTickets != 1 ? "s" : "" }}</h5>

      <v-btn
        v-if="maxTickets != 1"
        small
        :disabled="!cartTotal.quantity"
        @click="checkout"
        :text="!cartTotal.quantity"
        :color="'primary'"
        :block="$vuetify.breakpoint.xsOnly"
      >
        <v-icon left small>mdi-cart</v-icon>
        {{ cartTotal.quantity }}
        ingresso{{ cartTotal.quantity != 1 ? "s" : "" }} •
        {{ cartTotal.total | currency(true) }}
      </v-btn>
    </div>

    <v-tabs
      v-if="party.ChildParties.length"
      height="60px"
      class="mb-3"
      show-arrows
      center-active
      v-model="selectedParty"
    >
      <v-tab
        v-for="(childParty, index) in parties"
        :key="childParty.id"
        :tab-value="childParty.id"
        class="px-1"
      >
        <div class="d-flex align-center gap-2">
          <v-img
            :src="childParty.cover"
            :alt="childParty.name"
            height="45"
            :width="(45 * 16) / 9"
            class="rounded-lg"
            :aspect-ratio="16 / 9"
          />
          <div class="font-weight-medium">
            <p class="mb-1 text-start font-weight-bold lh-1">
              {{ childParty.name }}
            </p>
            <p v-if="index" class="mb-0 text-start text-12 lh-1">
              {{ childParty.date | date("DD [de] MMMM") }}
            </p>
            <p v-else class="mb-0 text-start text-12 lh-1">Evento principal</p>
          </div>
        </div>
      </v-tab>
    </v-tabs>

    <template v-if="ticketGroupsSorted.length">
      <v-card
        v-if="seller"
        outlined
        elevation="0"
        rounded="lg"
        class="pa-2 d-flex gap-2 mx-2 mb-3 align-center"
      >
        <baseAvatar
          :size="40"
          :src="seller.photo"
          :seed="seller.id"
        ></baseAvatar>
        <div class="flex-grow-1">
          <p class="text-overline lh-1 mb-0">Promoter</p>
          <p class="mb-0 font-weight-bold">{{ seller.name | firstName }}</p>
        </div>
        <v-btn
          v-if="!$route.query.seller"
          @click="$root.$emit('remove-seller')"
          small
          icon
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card>

      <v-expansion-panels focusable class="px-2" v-model="ticketGroupPanel">
        <v-expansion-panel
          v-for="(tg, i) in ticketGroupsSorted"
          :key="tg.id"
          :id="`ticket-group-${tg.id}`"
          :disabled="!tg.pending && !tg.lowestPrice"
          :style="
            tg.color
              ? `box-shadow: ${tg.color} 6px 0px 0px 0px inset !important`
              : undefined
          "
        >
          <v-expansion-panel-header>
            <div class="d-flex align-center gap-x-3 gap-y-1 flex-wrap">
              <div>
                <v-badge
                  inline
                  :content="ticketsPerGroup[tg.id]?.cart"
                  :value="!!ticketsPerGroup[tg.id]?.cart"
                >
                  <b class="text-16">{{ tg.name }}</b>
                </v-badge>
              </div>
              <v-scroll-y-transition leave-absolute>
                <div v-if="tg.benefits && i != ticketGroupPanel">
                  <v-alert
                    text
                    :key="key"
                    color="primary"
                    dense
                    class="py-1 text-12 px-2 mr-2 mb-0"
                  >
                    <div class="d-flex gap-1">
                      <v-icon
                        v-for="(benefit, key) in tg.benefits"
                        color="primary"
                        :key="key"
                        x-small
                      >
                        {{ getBenefitIcon(key) }}
                      </v-icon>
                    </div>
                  </v-alert>
                </div>
              </v-scroll-y-transition>
              <v-scroll-y-transition>
                <div class="d-flex gap-2" v-if="i != ticketGroupPanel">
                  <v-chip v-if="tg.TableGroup" color="primary" x-small>
                    <v-icon x-small left>
                      mdi-account{{
                        tg.TableGroup.capacity > 1 ? "-group" : ""
                      }}
                    </v-icon>
                    {{ tg.TableGroup.capacity }} pessoa{{
                      tg.TableGroup.capacity > 1 ? "s" : ""
                    }}
                  </v-chip>
                  <v-chip color="primary" v-if="tg.exclusive" x-small>
                    <v-icon x-small left> mdi-star-four-points </v-icon>
                    Exclusivo
                  </v-chip>
                  <v-chip
                    color="primary"
                    v-else-if="
                      periods.length && tg.periods.every((p) => p.access)
                    "
                    x-small
                  >
                    <v-icon x-small left> mdi-star-four-points </v-icon>
                    Acesso completo
                  </v-chip>
                </div>
              </v-scroll-y-transition>
            </div>
            <v-scroll-y-transition leave-absolute>
              <div v-if="i != ticketGroupPanel" class="flex-grow-0">
                <span
                  v-if="tg.lowestPrice"
                  class="text-end text--disabled pr-4"
                >
                  A partir de
                  {{ tg.lowestPrice | currency(true) }}
                </span>
                <span
                  v-else-if="tg.pending"
                  class="text-end text--disabled pr-4"
                >
                  Em breve
                </span>
                <span v-else class="text-end text--disabled pr-1">
                  Esgotado
                  <v-icon small class="text--disabled" right>
                    mdi-minus-circle
                  </v-icon>
                </span>
              </div>
            </v-scroll-y-transition>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-alert
              v-if="tg.ageGroup"
              text
              dense
              color="primary"
              icon="mdi-account-file"
              class="mt-2"
            >
              O acesso a este setor é exclusivo para
              <strong>maiores de {{ tg.ageGroup }} anos.</strong>
            </v-alert>
            <v-alert
              v-if="tg.requireBiometry"
              text
              dense
              color="info"
              icon="mdi-face-recognition"
              class="mt-2"
            >
              O acesso a esse setor é validado por reconhecimento facial.
              {{
                hasFace
                  ? "Seu cadastro já está pronto para uso."
                  : "Realize o cadastro do seu rosto após a compra."
              }}
            </v-alert>
            <v-alert
              type="info"
              text
              dense
              color="primary"
              class="mt-2"
              v-if="tg.TableGroup"
            >
              <p class="mb-0">
                Os ingressos desse setor são vendidos para
                <b class="font-weight-bold">
                  {{ tg.TableGroup.name }}s com lugar marcado
                </b>
                e
                <b class="font-weight-bold">
                  dão direito a {{ tg.TableGroup.capacity }} ingresso{{
                    tg.TableGroup.capacity > 1 ? "s" : ""
                  }}.</b
                >
              </p>
              <p class="mb-0">
                {{ tg.TableGroup.description }}
              </p>
            </v-alert>
            <div class="mt-3" v-if="tg.description">
              <p class="font-weight-bold mb-0">Descrição:</p>
              {{ tg.description }}
            </div>
            <div class="mt-3 d-flex flex-column" v-if="tg.benefits">
              <benefits-row :ticketGroup="tg" />
            </div>
            <v-divider class="my-2" v-if="tg.description" />
            <div v-if="periods.length">
              <div class="d-flex gap-x-4 gap-y-1 flex-wrap align-center">
                <p class="font-weight-bold mb-0">Períodos com acesso:</p>
                <v-chip
                  color="primary"
                  v-if="tg.periods.every((p) => p.access)"
                  x-small
                >
                  <v-icon x-small left> mdi-star-four-points </v-icon>
                  Acesso completo
                </v-chip>
              </div>

              <v-chip-group>
                <v-tooltip v-for="period in tg.periods" :key="period.id" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-on="on"
                      small
                      :color="period.access ? 'success' : 'warning'"
                      label
                      @click="scrollToPeriod(period.index)"
                      v-bind="attrs"
                    >
                      <span>
                        <v-icon x-small left>
                          {{ !period.access ? "mdi-lock" : "mdi-check" }}
                        </v-icon>
                        {{ period.name || `Período ${period.index + 1}` }}
                      </span>
                    </v-chip>
                  </template>
                  <span>
                    {{ period.access ? "Acesso liberado" : "Sem acesso" }}
                  </span>
                </v-tooltip>
              </v-chip-group>
            </div>

            <template v-if="tg.ticketBlocks.length">
              <v-list>
                <v-list-item dense class="px-0">
                  <h6 class="mb-0">Lotes</h6>
                </v-list-item>
                <v-list-item
                  class="border-1 px-1"
                  v-for="tb in tg.ticketBlocks"
                  :key="tb.id"
                >
                  <v-list-item-content>
                    <div
                      class="d-flex justify-space-between gap-x-2 gap-y-1 flex-wrap"
                    >
                      <div>
                        <p class="mb-1 text-16 lh-5 font-weight-medium">
                          {{ tb.name }}
                        </p>
                        <div class="d-flex align-center">
                          <b v-if="tb.status === 'available'">
                            <div
                              class="d-flex align-center gap-x-2 gap-y-1 flex-wrap"
                            >
                              <v-tooltip v-if="tb.BankRestriction" top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-img
                                    v-on="on"
                                    v-bind="attrs"
                                    :src="tb.BankRestriction.logo"
                                    width="28"
                                    height="28"
                                    contain
                                    class="rounded"
                                  />
                                </template>
                                <span>
                                  Exclusivo para clientes
                                  {{ tb.BankRestriction.name }}
                                </span>
                              </v-tooltip>
                              <div class="d-flex flex-column">
                                <span
                                  <span
                                  :class="{
                                    'text-decoration-line-through':
                                      tb.canUseMembership || tb.discount,
                                    'font-weight-medium':
                                      tb.canUseMembership || tb.discount,
                                    'text-16':
                                      tb.canUseMembership || tb.discount,
                                    'text-18':
                                      !tb.canUseMembership || !tb.discount,
                                    'text--disabled': tb.canUseMembership,
                                  }"
                                >
                                  {{ tb.price | currency(true) }}
                                </span>
                                <small
                                  v-if="tb.fee"
                                  class="text--secondary font-weight-medium"
                                >
                                  Taxa: +{{ tb.fee | currency(true) }}
                                </small>
                              </div>

                              <v-chip v-if="!!tg?.TableGroup" label small>
                                {{ tg.TableGroup.capacity }}
                                <!-- pessoa{{
                                tg.TableGroup.capacity > 1 ? "s" : ""
                              }} -->x
                                <!-- <v-icon small right left>
                                mdi-account{{
                                  tg.TableGroup.capacity > 1 ? "-group" : ""
                                }}
                              </v-icon> -->
                                <v-icon small right left>
                                  mdi-ticket-account
                                </v-icon>
                              </v-chip>
                              <b
                                v-if="tb.discount"
                                class="font-weight-bold text-18 ml-2"
                              >
                                {{ (tb.price - tb.discount) | currency(true) }}
                              </b>

                              <template v-if="tb.alreadyUsed">
                                <v-chip
                                  color="secondary"
                                  small
                                  label
                                  @click="showPriceModal({ tb, tg })"
                                >
                                  <v-icon x-small left>
                                    mdi-card-account-details-star
                                  </v-icon>
                                  Desconto já utilizado
                                </v-chip>
                              </template>
                              <template v-else-if="tb.canUseMembership">
                                <b class="font-weight-bold text-18 ml-2">
                                  {{
                                    tb.membershipPrice[0].price | currency(true)
                                  }}
                                </b>

                                <v-chip
                                  :color="
                                    tb.membershipPrice[0].Membership
                                      .backgroundColor
                                  "
                                  small
                                  text-color="white"
                                  label
                                  class="font-weight-medium text-14"
                                  :style="{
                                    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
                                      tb.membershipPrice[0].Membership
                                        .backgroundImg
                                    }), ${
                                      tb.membershipPrice[0].Membership
                                        .backgroundColor || '#3333'
                                    }80`,
                                    backgroundSize: 'cover',
                                    border: `1px solid ${
                                      tb.membershipPrice[0].Membership
                                        .backgroundColor || '#000'
                                    }`,
                                    boxShadow: `0 0 10px  ${
                                      tb.membershipPrice[0].Membership
                                        .backgroundColor || 'rgba(0,0,0,.2)'
                                    }`,
                                  }"
                                  @click="showPriceModal({ tb, tg })"
                                >
                                  <span
                                    :class="{
                                      'text-decoration-line-through':
                                        tb.alreadyUsed,
                                    }"
                                  >
                                    {{ tb.membershipPrice[0].Membership.name }}
                                  </span>
                                  <v-icon v-if="tb.alreadyUsed" right>
                                    mdi-check
                                  </v-icon>
                                </v-chip>
                              </template>

                              <template
                                v-else-if="
                                  tb.membershipPrice &&
                                  tb.membershipPrice.length
                                "
                              >
                                <v-chip
                                  color="primary"
                                  x-small
                                  icon="mdi-card-account-details-star"
                                  @click="showPriceModal({ tb, tg })"
                                >
                                  {{
                                    tb.membershipPrice.length > 1
                                      ? "A partir de"
                                      : ""
                                  }}
                                  <b class="font-weight-bold text-12 mx-1">
                                    {{
                                      tb.membershipPrice[0].price
                                        | currency(true)
                                    }}
                                  </b>

                                  para
                                  <b class="font-weight-bold text-12 mx-1">
                                    {{
                                      tb.membershipPrice.length > 1
                                        ? `socios`
                                        : tb.membershipPrice[0].Membership.name
                                    }}
                                  </b>

                                  <v-icon right small>
                                    mdi-chevron-down
                                  </v-icon>
                                </v-chip>
                                <!-- <span
                                class="font-weight-medium text-16 text--disabled ml-2"
                              >
                                Saiba Mais
                              </span> -->
                              </template>
                            </div>
                          </b>
                          <span v-else-if="tb.status === 'sold-out'">
                            Esgotado
                          </span>
                          <span v-else-if="tb.status === 'pending'">
                            Em breve
                          </span>
                        </div>
                        <small v-if="tb.useRequirement" class="text-12 text--disabled">
                          {{ tb.useRequirement }}
                        </small>
                      </div>

                      <template v-if="tb.status == 'available'">
                        <template v-if="tg.TableGroup">

                          <v-btn
                            v-if="maxTickets === 1 || !cart[tb.id]?.quantity"
                            color="primary"
                            :loading="loading === tb.id"
                            @click="selectTable(tb, tg)"
                            :disabled="
                              !!maxTickets &&
                              (ticketsPerGroup[tg.id]?.total >=
                                Math.min(maxTickets, tg.maxTickets) ||
                                partyTotal >= maxTickets)
                            "
                          >
                            Selecionar Lugar
                          </v-btn>
                          <v-card
                            v-else
                            outlined
                            class="d-flex align-center gap-2 rounded-pill pa-1 align-self-center"
                          >
                            <v-btn icon @click="removeItem(tb.id)" small>
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                            <b class="text-center">
                              {{ cart[tb.id].quantity }}
                            </b>
                            <v-btn
                              icon
                              @click="selectTable(tb, tg)"
                              small
                              :disabled="
                                !!maxTickets &&
                                (ticketsPerGroup[tg.id]?.total >=
                                  Math.min(maxTickets, tg.maxTickets) ||
                                  partyTotal >= maxTickets)
                              "
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-card>
                        </template>
                        <template v-else>
                          <v-btn
                            v-if="maxTickets === 1 || !cart[tb.id]?.quantity"
                            color="primary"
                            :loading="loading === tb.id"
                            @click="
                              buyTicket({ ticketGroup: tg, ticketBlock: tb })
                            "
                            :disabled="
                              !!Math.min(maxTickets, tg.maxTickets) &&
                              (ticketsPerGroup[tg.id]?.total >=
                                Math.min(maxTickets, tg.maxTickets) ||
                                partyTotal >= maxTickets)
                            "
                          >
                            {{ maxTickets === 1 ? "Comprar" : "Adicionar" }}
                          </v-btn>
                          <v-card
                            v-else
                            outlined
                            class="d-flex align-center gap-2 rounded-pill pa-1 align-self-center"
                          >
                            <v-btn icon @click="cart[tb.id].quantity--" small>
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                            <b class="text-center">
                              {{ cart[tb.id].quantity }}
                            </b>
                            <v-btn
                              icon
                              @click="
                                buyTicket({ ticketGroup: tg, ticketBlock: tb })
                              "
                              small
                              :disabled="
                                !!Math.min(maxTickets, tg.maxTickets) &&
                                (ticketsPerGroup[tg.id]?.total >=
                                  Math.min(maxTickets, tg.maxTickets) ||
                                  partyTotal >= maxTickets)
                              "
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-card>
                        </template>
                      </template>
                    </div>

                    <!-- <v-list-item-title>{{ tb.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="tb.status === 'available'">
                      <b>{{ tb.price | currency }}</b>
                    </span>
                    <span v-else-if="tb.status === 'sold-out'"> Esgotado </span>
                    <span v-else-if="tb.status === 'pending'"> Em breve </span>
                  </v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>

            <template v-if="tg.kitList.length > 0">
              <v-divider class="py-2 pb-0" v-if="tg.ticketBlocks.length" />
              <h6 class="mb-0 mt-2">Pacotes</h6>
              <kits-selector :tg="tg" @buyKit="buyKit" />
            </template>

            <p
              v-if="!!Math.min(maxTickets, tg.maxTickets)"
              class="mb-0 text-center text-12 text--secondary"
            >
              Limite de
              {{ Math.min(maxTickets, tg.maxTickets) }} ingresso{{
                Math.min(maxTickets, tg.maxTickets) != 1 ? "s" : ""
              }}
              por pessoa para esse setor.
            </p>
            <p
              v-if="ticketsPerGroup[tg.id]?.user"
              class="mb-0 text-center text-12 text--disabled"
            >
              Você possui
              {{ ticketsPerGroup[tg.id]?.user }} ingresso{{
                ticketsPerGroup[tg.id]?.user != 1 ? "s" : ""
              }}
              para esse setor.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Cupons -->
      <coupon-apply
        :party="party"
        :coupon="coupon"
        @refresh="$emit('refresh', false)"
      />

      <!-- Meios de pagamento aceito -->
      <accept-payment-methods
        :disallowOnlinePaymentMethods="party.disallowOnlinePaymentMethods"
        class="mx-1 mt-2"
      />
    </template>

    <no-ticket-group-available
      v-else
      :party="party"
      :ticketOffice="ticketOffice"
    />

    <!-- Float action -->
    <v-slide-y-reverse-transition>
      <div
        v-if="cartTotal.quantity"
        style="
          position: fixed;
          bottom: 0px;
          left: 0px;
          right: 0px;
          z-index: 10;
          pointer-events: none;
        "
        class="d-flex justify-center pa-3 py-4 mb-16 mb-md-0"
        key="cart-info"
      >
        <v-card
          elevation="24"
          class="d-flex align-center pa-2 gap-3 flex-grow-"
          rounded="xl"
          light
          style="pointer-events: all"
        >
          <v-icon>mdi-cart</v-icon>
          <span class="text-16 font-weight-medium flex-grow-1">
            {{ cartTotal.quantity }}
            ingresso{{ cartTotal.quantity != 1 ? "s" : "" }} •
            {{ cartTotal.total | currency(true) }}
          </span>
          <v-btn color="primary" class="rounded-xl" @click="checkout">
            Finalizar Compra
          </v-btn>
        </v-card>
      </div>
    </v-slide-y-reverse-transition>

    <v-slide-y-reverse-transition>
      <div
        v-if="!cartTotal.quantity && cartTotal.products?.quantity"
        style="
          position: fixed;
          bottom: 0px;
          left: 0px;
          right: 0px;
          z-index: 10;
          pointer-events: none;
        "
        class="d-flex justify-center pa-3 py-4 mb-16 mb-md-0"
      >
        <v-card style="pointer-events: all" elevation="24" class="rounded-lg">
          <v-alert
            type="info"
            text
            class="mb-0"
            border="bottom"
            icon="mdi-ticket"
          >
            Adicione um ingresso para continuar a compra.
          </v-alert>
        </v-card>
      </div>
    </v-slide-y-reverse-transition>

    <select-table
      v-if="tableMap"
      :party="party"
      :cart="cart"
      :table-map="tableMap"
      @select="buyTicket"
    />
    <kit-details-modal :party="party" />
    <ticket-block-membership-price :party="party" />
    <require-sign-in @success="successSign" />
    <buy-ticket
      :party="party"
      :cart="cart"
      :productCart="productCart"
      :seller="seller"
      :products="products"
      @success="refresh"
    />
    <buy-kit
      :party="party"
      :kit="kit"
      :products="products"
      @success="refresh"
    />
    <product-details-modal
      v-if="products && products.length"
      :party="party"
      :productCart="productCart"
    />
    <pre-purchase-alert-modal ref="prePurchaseAlert" />
    <ticket-group-benefits-viewer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PartyStatus from "@/utils/partyStatus";
import AcceptPaymentMethods from "../global/AcceptPaymentMethods.vue";
import BuyTicket from "./modal/BuyTicket.vue";
import BuyKit from "./modal/BuyKit.vue";
import RequireSignIn from "./modal/RequireSignIn.vue";
import SelectTable from "./modal/SelectTable.vue";
import KitsSelector from "./KitsSelector.vue";
import TicketBlockMembershipPrice from "./modal/TicketBlockMembershipPrice.vue";
import { process } from "@/utils/shop/cart";
import KitDetailsModal from "./modal/KitDetailsModal.vue";
import CouponApply from "./CouponApply.vue";
import ProductRowSelect from "./ProductRowSelect.vue";
import ProductDetailsModal from "./modal/ProductDetailsModal.vue";
import NoTicketGroupAvailable from "./NoTicketGroupAvailable.vue";
import PrePurchaseAlertModal from "./PrePurchaseAlertModal.vue";
import TicketGroupBenefitsViewer from "./modal/TicketGroupBenefitsViewer.vue";
import BenefitsRow from "@/components/global/party/BenefitsRow.vue";

export default {
  components: {
    RequireSignIn,
    BuyTicket,
    BuyKit,
    AcceptPaymentMethods,
    TicketBlockMembershipPrice,
    SelectTable,
    KitsSelector,
    KitDetailsModal,
    CouponApply,
    ProductRowSelect,
    ProductDetailsModal,
    NoTicketGroupAvailable,
    PrePurchaseAlertModal,
    TicketGroupBenefitsViewer,
    BenefitsRow,
  },
  data: () => ({
    loading: false,
    ticketGroupPanel: null,
    cart: {},
    productCart: {},
    kit: {},
    statusOrder: ["sold-out", "available", "pending"],
    selectedParty: null,
  }),
  methods: {
    removeItem(id) {
      if (this.cart[id].quantity > 0) {
        this.cart[id].quantity--;

        if (this.cart[id].tables.length > 0)
          this.cart[id].tables = [...this.cart[id].tables].slice(0, -1);
      }
    },
    showPriceModal({ tb, tg }) {
      this.$root.$emit("ticket-block-membership-price", {
        ticketBlock: tb,
        ticketGroup: tg,
      });
    },
    refresh() {
      this.$emit("refresh");
    },
    selectTable(ticketBlock, ticketGroup) {
      if (!this.isAuthenticated) {
        this.$root.$emit("require-sign-in");
        return;
      }
      this.$root.$emit("select-table", { ticketBlock, ticketGroup });
    },
    successSign() {
      this.refresh();
    },
    promiseSignIn() {
      const promise = new Promise((resolve, reject) => {
        this.promiseSignInData = { resolve, reject };
      });
      return promise;
    },
    async buyKit({ kit }) {
      try {
        if (!this.isAuthenticated) {
          this.$root.$emit("require-sign-in");
          return;
        }
        return this.$root.$emit("buy-kit", {
          kit: kit,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async buyTicket({ ticketBlock, ticketGroup, table }) {
      try {
        this.$root.$emit("add_to_cart", {
          ticketBlock,
          ticketGroup,
        });

        if (!this.isAuthenticated) {
          this.$root.$emit("require-sign-in");
          return;
        }

        if (
          ticketBlock.prePurchaseAlert &&
          !this.cart[ticketBlock.id] &&
          !(await this.$refs.prePurchaseAlert.open(ticketBlock))
        ) {
          return;
        }

        if (
          ticketBlock.BankRestriction &&
          !this.cart[ticketBlock.id]
        ) {
          if (!(await this.$refs.prePurchaseAlert.open(ticketBlock))) return;
        }

        if (this.maxTickets === 1)
          return this.$root.$emit("buy-ticket", {
            code: this.code,
            coupon: this.coupon,
            cart: {
              [ticketBlock.id]: {
                ticketBlock,
                ticketGroup,
                tables: table ? [table] : [],
                quantity: 1,
              },
            },
          });

        if (!this.cart[ticketBlock.id]) {
          const data = {
            ticketBlock,
            ticketGroup,
            tables: [],
            quantity: 0,
          };
          this.$set(this.cart, ticketBlock.id, data);
        }
        const currentTicketBlock = this.cart[ticketBlock.id];
        this.$set(this.cart, ticketBlock.id, {
          ...currentTicketBlock,
          tables: table
            ? [...currentTicketBlock.tables, table]
            : currentTicketBlock.tables,
          quantity: currentTicketBlock.quantity + 1,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async checkout() {
      try {
        this.loading = true;
        if (!this.isAuthenticated) {
          this.$root.$emit("require-sign-in");
          return;
        }
        this.$emit("changeParty", null);
        this.selectedParty = null;
        this.$root.$emit("buy-ticket", {
          code: this.code,
          cart: Object.assign({}, this.cart),
          coupon: this.coupon,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    updateCart() {
      const ticketBlocks = this.ticketGroupsSorted.flatMap(
        ({ ticketBlocks }) => ticketBlocks
      );
      const d = Object.entries(this.cart).map(([ticketBlockId, data]) => {
        const ticketBlock = ticketBlocks.find(({ id }) => id === ticketBlockId);
        if (!ticketBlock || !data) return [ticketBlockId, data];

        return [
          ticketBlockId,
          {
            ...data,
            ticketBlock: this.processTicketBlock(ticketBlock, data.ticketGroup),
          },
        ];
      });
      this.cart = Object.fromEntries(d);
    },
    addProductToCart({ variant, product }) {
      if (!this.isAuthenticated) {
        this.$root.$emit("require-sign-in");
        return;
      }

      if (!this.productCart[variant.id]) {
        this.$set(this.productCart, variant.id, {
          quantity: 1,
          variant,
          product,
        });
      } else this.productCart[variant.id].quantity++;
    },
    removeProductFromCart({ variant }) {
      if (this.productCart[variant.id].quantity > 0)
        this.productCart[variant.id].quantity--;
      if (this.productCart[variant.id].quantity === 0)
        this.$delete(this.productCart, variant.id);
    },
    scrollToPeriod(index) {
      var el = document.getElementById(`period-${index + 1}`);
      if (index < 1) el = document.getElementById("periods");
      if (el)
        this.$vuetify.goTo(el, {
          offset: 30,
          behavior: "smooth",
        });
    },
    processTicketBlock(tb, tg) {
      return {
        ...tb,
        fee: this.calculateFee(tg, tb),
        membershipPrice:
          tb.membershipPrice ||
          [].sort((a, b) => {
            if (a.canUse && b.canUse) return a.price - b.price;
            if (a.canUse) return -1;
            if (b.canUse) return 1;
            return a.price - b.price;
          }),
        canUseMembership: (tb.membershipPrice || []).some(
          ({ canUse }) => canUse
        ),
        alreadyUsed: (tb.membershipPrice || []).some(
          ({ alreadyUsed }) => alreadyUsed
        ),
      };
    },
    calculateFee(ticketGroup, ticketBlock) {
      if (ticketBlock.status !== "available") return 0;
      if (this.fees.length === 0) return 0;

      const fee = this.fees.reduce((acc, fee) => {
        // ticketBlockId is the most specific
        if (acc?.ticketBlockId) return acc;
        if (fee.ticketBlockId && fee.ticketBlockId !== ticketBlock.id)
          return acc;

        // ticketGroupId is the second most specific
        if (acc?.ticketGroupId) return acc;
        if (fee.ticketGroupId && fee.ticketGroupId !== ticketGroup.id)
          return acc;

        return fee;
      }, null);
      if (!fee) return 0;

      const { fixed, percent } = fee.outerFee;
      const ticketPrice = ticketBlock.price - (ticketBlock.discount || 0);
      return fixed + (ticketPrice + fixed) * (percent / 100);
    },
    getBenefitIcon(key) {
      const benefitsMap = {
        OPEN_BAR: "mdi-glass-mug-variant",
        OPEN_FOOD: "mdi-hamburger",
        SITTING_AREA: "mdi-seat",
        BATHROOMS: "mdi-toilet",
        AIR_CONDITIONING: "mdi-air-conditioner",
        LOCKERS: "mdi-locker",
        WIFI: "mdi-wifi",
        WAITER: "mdi-room-service",
        SAFE_SPACE: "mdi-heart-pulse",
      };
      return benefitsMap[key] || "mdi-star";
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "user"]),
    status() {
      const party = this.party;
      if (!party) return false;
      const status = new PartyStatus(party);
      return status.isActive || status.status;
    },
    hasFace() {
      return (
        !!this.isAuthenticated &&
        (this.user?.Biometrics || []).some(({ type }) => type === "FACE")
      );
    },
    partyTotal() {
      return Object.values(this.ticketsPerGroup).reduce(
        (acc, { total }) => acc + total,
        0
      );
    },
    ticketsPerGroup() {
      if (!this.tickets) return {};
      const userTickets = this.tickets.reduce((acc, ticket) => {
        const ticketGroupId = ticket.TicketBlock.TicketGroup.id;
        if (!acc[ticketGroupId])
          acc[ticketGroupId] = { cart: 0, user: 0, total: 0 };
        acc[ticketGroupId].user += 1;
        acc[ticketGroupId].total += 1;
        return acc;
      }, {});

      return Object.values(this.cart).reduce(
        (acc, { quantity, ticketGroup }) => {
          if (!acc[ticketGroup.id])
            acc[ticketGroup.id] = { cart: 0, user: 0, total: 0 };
          acc[ticketGroup.id].cart += quantity;
          acc[ticketGroup.id].total += quantity;
          return acc;
        },
        userTickets
      );
    },

    cartTotal() {
      return this.cartProcessed.totalValue;
    },
    cartProcessed() {
      return process(this.cart, this.productCart);
    },
    parties() {
      const party = this.baseParty || this.party;
      if (!party) return [];
      if (!this.party.ChildParties) return [this.party];

      if (!this.ticketGroups.some((tg) => tg.partyId === party.id)) {
        return [...this.party.ChildParties];
      }
      return [party, ...this.party.ChildParties];
    },

    ticketGroupsSorted() {
      if (this.status !== true) return [];
      const parties = Object.fromEntries(this.parties.map((p) => [p.id, p]));
      const ticketGroups = this.ticketGroups
        .filter(
          (tg) => !this.selectedParty || tg.partyId === this.selectedParty
        )
        .map((tg) => {
          return {
            ...tg,
            periods: this.periods.map((p, index) => ({
              ...p,
              index,
              access: p.TicketGroup.some(({ id }) => id === tg.id),
            })),
            TableGroup: this.tableMap?.Groups.find(
              ({ id }) => id === tg.tableGroupId
            ),
            party: parties[tg.partyId],
            ticketBlocks: tg.ticketBlocks
              .map((tb) => this.processTicketBlock(tb, tg))
              .sort((a, b) => {
                const aStatus = this.statusOrder.indexOf(a.status);
                const bStatus = this.statusOrder.indexOf(b.status);
                if (aStatus === bStatus) {
                  return 0;
                }
                return aStatus - bStatus;
              }),
            kitList: tg.kitList.map((kit) => {
              return {
                ...kit,
                fee: this.calculateFee(tg, kit),
              };
            }),
            pending: [...tg.ticketBlocks, ...tg.kitList].some(
              (tb) => tb.status === "pending"
            ),
            lowestPrice:
              [...tg.ticketBlocks, ...tg.kitList]
                .filter((tb) => tb.status === "available")
                .sort((a, b) => (a.price || 0) - (b.price || 0))[0]?.price ||
              null,
          };
        })
        .sort((a, b) => {
          // sort by status (available first, then pending, then sold-out) and then by name
          const aIsAvailable = a.lowestPrice;
          const bIsAvailable = b.lowestPrice;
          if (aIsAvailable && !bIsAvailable) return -1;
          if (!aIsAvailable && bIsAvailable) return 1;

          const aIsPending = a.pending && !a.lowestPrice;
          const bIsPending = b.pending && !b.lowestPrice;
          if (aIsPending && !bIsPending) return 1;
          if (!aIsPending && bIsPending) return -1;

          return a.order - b.order;
        });

      if (ticketGroups.length > 0 && ticketGroups[0].lowestPrice)
        this.ticketGroupPanel = 0;

      return ticketGroups;
    },
    maxTickets() {
      if (!this.coupon || !this.coupon?.coupon?.maxTickets)
        return this.party.maxTickets;
      return this.coupon.coupon?.maxTickets || this.party.maxTickets;
    },
  },
  watch: {
    selectedParty() {
      this.$emit("changeParty", this.selectedParty);
      this.$nextTick(() => {
        this.ticketGroupPanel = 0;
      });
    },
    productCart: {
      handler() {
        this.$root.$emit("update-product-cart", this.productCart);
      },
      deep: true,
    },
    ticketGroups: {
      handler() {
        this.updateCart();
      },
      deep: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    baseParty: {
      type: Object,
      default: () => null,
    },
    periods: {
      type: Array,
      default: () => [],
    },
    tickets: {
      type: Array,
      default: () => [],
    },
    ticketGroups: {
      type: Array,
      required: true,
    },
    code: {
      type: String,
    },
    tableMap: {
      type: Object,
      default: () => ({}),
    },
    fees: {
      type: Array,
      default: () => [],
    },
    seller: {
      type: Object,
      default: () => null,
    },
    coupon: {
      type: Object,
      default: () => null,
    },
    products: {
      type: Array,
      default: () => [],
    },
    ticketOffice: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$root.$on("select-ticket-group", (ticketGroupId) => {
      const index = this.ticketGroupsSorted.findIndex(
        (tg) => tg.id === ticketGroupId
      );
      if (index > -1) this.ticketGroupPanel = index;
    });

    this.$root.$on("add-product-to-cart", this.addProductToCart);
    this.$root.$on("remove-product-from-cart", this.removeProductFromCart);
    this.$root.$on("update-cart", this.updateCart);
  },
};
</script>

<style>
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 32px;
}
</style>
